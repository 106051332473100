// Colors
//---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);

// Base

$color-slate-blue: #6944d4;
$color-medium-slate-blue: #764bf1;
$color-blue-text-hover: #931FEE;
$color-blue-background-hover: #5737B6;
$color-selective-yellow: #ffb901;
$color-black-russian: #121213;
$color-bastille: #2e2e32;
$color-lime-green: #13d927;
$color-success: #0fb059;
$color-success-hover: #219653;
$color-storm-grey: #717384;
$color-grey: #9CA8BF;
$color-grey-disabled: #ffffff3d;
$color-quartz: #e0e0e9;
$color-ghost-white: #f4f4fc;
$color-ghost-white-lighter: #f9fafc;
$color-white: #ffffff;
$color-cinnabar: #ec3737;
$color-sunset-orange: #f25151;
$color-sunset-orange-hover: #de2b2b;
$color-black: #131314;
$color-new-black: #091834;

// Border radius
//---------------------------------

$border-radius-row: 0.25rem;
$border-radius-input: 0.5rem;
$border-radius-modal: 1.5rem;
$border-radius-button: 0.75rem;

// Typography
//---------------------------------

$font-family--popins: "Poppins", "Arial", sans-serif;
$font-family--helvetica-neue: "HelveticaNeue", "Arial", sans-serif;
$font-family--neue-machina: "NeueMachina", "Arial", sans-serif;

// Spacing
//---------------------------------

$spacing-layout-m: 6.25rem;
$spacing-layout-sm: 4rem;

$spacing-xxlg: 2.5rem;
$spacing-xlg: 2rem;
$spacing-lg: 1.5rem;
$spacing-xm: 1rem;
$spacing-m: 0.75rem;
$spacing-sm: 0.5rem;
$spacing-xsm: 0.25rem;
$spacing-xxsm: 0.125rem;

// Icon size
//---------------------------------

$icon-size-xlg: 2.5rem;
$icon-size-lg: 1.5rem;
$icon-size-m: 1.25rem;
$icon-size-sm: 1rem;

// Font size
//---------------------------------

$font-size-xxlg: 2rem;
$font-size-xlg: 1.5rem;
$font-size-lg: 1.25rem;
$font-size-m: 1rem;
$font-size-sm: 0.875rem;
$font-size-xsm: 0.75rem;

// Font weight
//---------------------------------

$font-weight-slim: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-extra-bold: 700;

// Animation
//---------------------------------

$trans--300: 0.3s ease;

// Viewports
//---------------------------------

$vp--320: 320px;
$vp--375: 375px;
$vp--768: 768px;
$vp--1024: 1024px;
$vp--1160: 1160px;
$vp--1240: 1240px;

// Retina
//---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;


