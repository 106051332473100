@import 'src/styles/variables';
@import 'src/styles/mixins';

.login {
  max-width: 20rem;
  margin: 40px auto 0;
  padding: 1rem;
  padding-bottom: 2.5rem;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 0 6px 6px #f7f7f7;
  box-sizing: border-box;
}

.subtitle {
  color: $color-storm-grey;
  font-size: $font-size-m;
}

.title {
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 8px;
}

.bottom {
  border-top: 1px solid $color-quartz;
  padding-top: 32px;
  margin-top: 32px;
  font-size: $font-size-m;
  font-weight: $font-weight-bold;
  color: $color-storm-grey;

  a {
    color: $color-slate-blue;
  }

  @include vp--1023 {
    text-align: center;
  }
}
