@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-m;
  font-weight: 600;
  border-radius: 12px;
  width: 100%;
  background-color: rgba(19, 217, 39, 0.08);
  color: $color-lime-green;
  padding: 0 16px;

  &.small {
    height: 24px;
    font-size: 10px;
    border-radius: 4px;
  }

  &.yellow {
    color: $color-selective-yellow;
    background-color: #FFB90114;
  }

  &.purple {
    color: $color-medium-slate-blue;
    background-color: #764bf114;
  }
}

