@import 'src/styles/variables';
@import 'src/styles/mixins';

.product {
  position: relative;
  height: 26.625rem;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: $spacing-xxlg $spacing-xlg;
  width: 16.938rem;
  font-weight: $font-weight-normal;

  &.white {
    background-color: transparent;
  }

  &.black {
    background-color: $color-new-black;
    color: $color-default-white
  }
}

.top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.name {
  span {
    margin-left: $spacing-xm;
    font-size: $font-size-lg;
    font-weight: $font-weight-extra-bold;
  }

  &.white {
    color: $color-black-russian;
  }

  &.black {
    color: $color-selective-yellow
  }
}

.price {
  font-size: $font-size-xlg;
  font-weight: $font-weight-slim;
  height: 1.5rem;
}

.fullWidth {
  width: 100%;
}

.list {
  font-size: $font-size-xsm;
  list-style-type: inherit;
  padding-left: 0.875rem;

  &.white {
    color: $color-storm-grey;
  }

  &.black {
    color: $color-default-white
  }
}

.chip {
  position: absolute;
  right: 0;
  top: $spacing-m;

  img {
    position: relative;
  }

  span {
    top: 0;
    right: 0.75rem;
    color: $color-white;
    position: absolute;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-sm;
  }
}

.skeletonRowWrapper {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-xm;
}

.skeletonList {
  list-style-type: inherit;
  color: $color-storm-grey;
  margin-left: $spacing-xm;

  li > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.skeletonButton {
  width: 100%;

  div {
    width: 100%;
  }
}
