@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;

  span {
    font-weight: $font-weight-bold;
  }

  button {
    background: $color-quartz;
    height: 1.625rem;
    width: 3.125rem;
    display: flex;
    align-items: center;
  }

  button > div:before {
    height: 1.375rem;
    width: 1.375rem;
    background-color: $color-medium-slate-blue;
    border-radius: 50%;
  }
}

.switcherChecked {
  div:before {
    left: calc(100% - 1.375rem);
  }
}

.isChecked {
  color: $color-medium-slate-blue;
}
