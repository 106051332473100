@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  display: flex;
  align-items: center;
  gap: $spacing-lg;

  header {
    font-size: $font-size-xxlg;
    font: $font-family--neue-machina;
    font-weight: $font-weight-slim;
  }
}

.message {
  :first-child {
    color: $color-storm-grey;
  }
  :last-child {
    margin-left: $spacing-xxsm;
    color: $color-cinnabar;
  }
}

.actionButtonsGroup {
  display: flex;
  align-items: center;
  gap: $spacing-lg;
}
