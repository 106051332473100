@import 'src/styles/variables';
@import 'src/styles/mixins';

.loader {
  animation: spinner 1.5s linear infinite;
  color: $color-medium-slate-blue
}


.small {
  width: 1rem;
  height: 1rem;
}

.medium {
  width: 1.875rem;
  height: 1.875rem;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
  }
}

