@import 'src/styles/variables';
@import 'src/styles/mixins';

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 5px solid $color-medium-slate-blue;
  border-right-color: transparent;
  position: relative;
  animation-name: spinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader::after,
.loader::before {
  content: "";
  background-color: transparent;
  position: absolute;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  z-index: -1;
  box-sizing: content-box;
}

.loader::after {
  width: 54px;
  height: 54px;
  border: 5px solid #F25151;
  border-left-color: transparent;
  left: -12px;
  top: -12px;
}

.loader::before {
  width: 68px;
  height: 68px;
  border: 5px solid #FFB901;
  border-bottom-color: transparent;
  left: -19px;
  top: -19px;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}
