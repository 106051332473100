@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    font-family: inherit;
    color: $color-medium-slate-blue;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  button:hover {
    color: $color-slate-blue;
  }
}
