@import 'src/styles/variables';
@import 'src/styles/mixins';

.dropdown {
  width: 25rem;
  top: 0.375rem;
  right: 0;
  z-index: 1;
  background: $color-white;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: $border-radius-button;
  position: absolute;
  margin-top:$spacing-xsm;
  padding: $spacing-xm $spacing-lg;
}

.row:not(:last-child) {
  margin-bottom: $spacing-lg;
}
