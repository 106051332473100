@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  height: 100vh;
}

.title {
  font-size: 32px;
  line-height: 48px;
}

.left, .right {
  width: 100%;
  height: 100%;
  position: relative;
}

.left {
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 960px;
  padding-top: 32px;

  &:before {
    content: "";
    background: radial-gradient(59.17% 150.05% at 43.12% 67.5%, #121213 68.69%, #6944D4 79.18%, #F25151 83.18%, #FFB901 87.39%);
    width: 128%;
    height: 220%;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, 0%);
  }

  .logo {
    margin-bottom: 114px;

    path:first-child {
      fill: $color-default-white;
    }
  }

  .title {
    color: $color-default-white;
  }

  @include vp--1023 {
    display: none;
  }
}

.content {
  position: relative;
  z-index: 1;
}


.back {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-storm-grey;
  margin-bottom: 40px;

   @include vp--1023 {
    color: $color-slate-blue;
  }

  &:hover {
    background-color: $color-slate-blue;
    border-color: $color-slate-blue;

    svg {
      color: $color-default-white;
    }
  }

  &:active {
    background-color: transparent;
    border-color: $color-slate-blue;
  }
}


.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 24px;

  .back {
    display: none;
    border-color: $color-slate-blue;

    &:hover path {
      fill: $color-default-white;
    }

    &:active path {
      fill: $color-slate-blue;
    }

    @include vp--1023 {
      display: flex;
    }
  }

  .logo {
    display: none;
    margin-bottom: 30px;

    @include vp--1023 {
      display: block;
      align-self: flex-start;
    }
  }

  @include vp--1023 {
    justify-content: space-between;
  }
}

.features {
  margin-top: 32px;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  span {
    color: $color-default-white;
    margin-left: 24px;
    font-size: 18px;
    font-weight: $font-weight-bold;
  }
}

.center {
  max-width: 320px;
  width: 100%;
}

.terms {
  font-size: $font-size-m;
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  text-align: center;
  color: $color-black;
  padding: 0 24px;

  a {
    color: $color-slate-blue;
  }

  @include vp--1023 {
    position: static;
    margin-top: 30px;
  }
}
