@import "src/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
      color: $color-blue-text-hover;

      h3 {
        color: $color-blue-text-hover;
      }
    }

    svg {
      color: $color-slate-blue;
    }

    h3 {
      color: $color-slate-blue;
    }
  }
}
