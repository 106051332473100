@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: absolute;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 2.5rem;
  top: $spacing-layout-sm;
  left: 10%;
  border-radius: $border-radius-button;
  padding: 0 $spacing-xm;
}
