@import 'src/styles/variables';
@import 'src/styles/mixins';

.input {
  border: 1px solid $color-medium-slate-blue;
  border-radius: $border-radius-input;
  height: 56px;
  padding-left: 16px;
  padding-right: 40px;
  width: 100%;
  color: $color-black;
  font-weight: $font-weight-bold;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border: 2px solid $color-medium-slate-blue;
    padding-left: 15px;
    padding-right: 39px;
  }

  &::placeholder {
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
    color: $color-storm-grey;
    font-family: $font-family--popins;
  }

  &.readOnly {
    border-color: $color-quartz;
  }
}

.copyButton {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: transparent;
  border: none;
  border-radius: 0 12px 12px 0;
  transition: background-color $trans--300, opacity $trans--300;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @include vp--767 {
    top: 34px;
  }
}

.label {
  color: $color-storm-grey;
  font-size: 12px;
  margin-bottom: 6px;
}

.wrapper {
  position: relative;
}

.icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}


.copyStatus {
  content: '';
  position: absolute;
  right: 0;
  top: -24px;
  padding-left: 8px;
  font-family: $font-family--popins;
  font-size: 12px;
  font-weight: $font-weight-normal;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: $color-storm-grey;

  @include vp--767 {
    right: 0;
    top: 0;
  }

  &::before {
    content: '';
    left: 0;
    position: absolute;
    width: 1px;
    height: 14px;
    background-color: $color-slate-blue;
  }
}
