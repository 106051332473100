@import 'src/styles/variables';
@import 'src/styles/mixins';

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  margin-bottom: $spacing-lg;
}

.title {
  font-size: $font-size-xlg;
  font-weight: $font-weight-bold;
}

.close {
  color: $color-medium-slate-blue;
  cursor: pointer;
  background: transparent;
  border: none;
}

.close:hover {
  color: $color-slate-blue;
}

.formWrapper {
  :global {
    .ant-form-item-explain-error {
      color: $color-cinnabar
    }
  }
}
