@import 'src/styles/variables';
@import 'src/styles/mixins';

.blockchain {
  display: flex;
  align-items: center;
  font-size: $font-family--popins;
  font-weight: $font-weight-normal;


  img {
    margin-right: 0.875rem;
    width: 1.5rem;
  }
}
