@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin vp--1239 {
  @media (max-width: $vp--1240 - 1) {
    @content;
  }
}

@mixin vp--1279 {
  @media (max-width: $vp--1280 - 1) {
    @content;
  }
}

@mixin vp--1159 {
  @media (max-width: $vp--1160 - 1) {
    @content;
  }
}

@mixin vp--1023 {
  @media (max-width: $vp--1024 - 1) {
    @content;
  }
}

@mixin vp--767 {
  @media (max-width: $vp--768 - 1) {
    @content;
  }
}

@mixin vp--374 {
  @media (max-width: $vp--375 - 1) {
    @content;
  }
}

@mixin vp--319 {
  @media (max-width: $vp--320 - 1) {
    @content;
  }
}
