@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  padding: $spacing-sm 0;
  font-weight: $font-weight-bold;
  font-size: $font-size-m;
  height: 100%;
  width: 100%;

  svg {
    align-self: flex-end;
  }

}

.closeButton {
  cursor: pointer;
  background: transparent;
  border: none;
  color: $color-white;
  position: absolute;
  right: $spacing-m;
}
.closeButton:hover {
  color: $color-quartz;
}


.error {
  background: $color-sunset-orange;
}
.warning {

}
.success {
  background: $color-success;
}
.info {

}
