@import 'src/styles/variables';
@import 'src/styles/mixins';

.deleteModalWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    margin-right: $spacing-lg;
  }
}

.deleteModalInfo {
  color: $color-storm-grey;
}

.deleteModalButtonsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 11.75rem;
  }
}
