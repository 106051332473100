@import 'src/styles/variables';
@import 'src/styles/mixins';

.appModalWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: $color-medium-slate-blue;
    font-weight: $font-weight-normal;
    font-size: $font-size-m;
    text-decoration: underline;
  }

  a:hover {
    color: $color-slate-blue;
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 11.75rem;
  }
}

.modalHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-sm;
  font-size: $font-size-xlg;
  font-weight: $font-weight-bold;
}

.appSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: inherit;
  height: 5.25rem;
  width: 100%;
  background: #EAF2FD;
  border-radius: $border-radius-button;
  gap: $spacing-xsm;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-xm;
    margin-left: $spacing-xm;
    z-index: 1;

    span {
      font-size: $font-size-m;
      font-weight: 600;
    }
  }
}

.cardAppBackground {
  position: absolute;
  opacity: 10%;
  z-index: 0;
}

.deleteAppButton {
  cursor: pointer;
  position: absolute;
  color: $color-sunset-orange;
  text-decoration: underline;
  width: 6.5rem;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  top: 13.5rem;
  right: 2.75rem;
  font-size: $font-size-m;
  font-weight: $font-weight-normal;
}

.deleteAppButton:hover {
  color: $color-sunset-orange-hover;
}
