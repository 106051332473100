@import 'src/styles/variables';
@import 'src/styles/mixins';

.block {
  display: flex;
  flex-grow: 1;
  padding-right: 4rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 15px;

  @include vp--374 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.button {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  max-width: 148px;
  min-height: 50px;
  background-color: $color-default-white;
  font-family: $font-family--popins;
  font-size: $font-size-sm;
  line-height: 20px;
  color: $color-bastille;
  outline: none;
  border: 1px solid $color-quartz;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color $trans--300, border-color $trans--300,
  color $trans--300;

  &:hover {
    background-color: $color-slate-blue;
    color: $color-default-white;
  }

  &:active {
    color: $color-bastille;
    background-color: $color-default-white;
    border-color: $color-slate-blue;
  }

  &:disabled {
    pointer-events: none;
  }
}

.loader {
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  padding-top: 200px;
}


.content {
  display: flex;
  flex-direction: column;
  padding: $spacing-xxlg $spacing-layout-sm $spacing-layout-m $spacing-xxlg;
}

.tableWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $font-size-lg;
  height: 16rem;
  width: 100%;

  button {
    margin-top: $font-size-m;
  }

  div {
    color: $color-black;
    text-align: center;
  }
}
