@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;

  thead {
    height: 2rem;
    border-bottom: 0.063rem solid $color-quartz;
    font-size: 12px;

    th {
      width: 25%;
    }
  }

  tbody {
    tr {
      border-bottom: 0.063rem solid $color-quartz;
    }
    td {
      padding: $spacing-xm 0;
      vertical-align: middle;
    }
  }
}

.errorWrapper {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    font-size: $font-size-lg;
    font-weight: $font-weight-normal;
  }

  button {
    margin-top: $spacing-lg;
  }
}
