@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: $font-family--popins;
  font-weight: 300;
  font-size: $font-size-m;

  img {
    margin-bottom: 30px;
  }
}
