@import 'src/styles/variables';
@import 'src/styles/mixins';

.user {
  position: relative;
  cursor: pointer;
  border: none;
  background: transparent;

  svg {
    transition: opacity $trans--300;
  }
}

.userActive {

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: $color-black-russian;

    @include vp--767 {
      display: none;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;

  &:hover,
  &:active {
    .email,
    svg {
      opacity: 0.7;
    }
  }
}

.icon {
  display: none;

  @include vp--767 {
    display: block;
    margin-right: 5px;
  }
}

.iconArrow {
  @include vp--767 {
    display: none;
  }
}

.email {
  margin-right: 5px;
  font-family: $font-family--popins;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.01em;
  color: $color-black-russian;
  transition: opacity $trans--300;

  @include vp--767 {
    display: none;
  }
}
