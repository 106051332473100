@import 'src/styles/variables';
@import 'src/styles/mixins';

.input {
  border-radius: $border-radius-input;
  height: 56px;
  padding-left: 16px;
  padding-right: 40px;
  width: 100%;
  font-weight: $font-weight-bold;
  outline: none;
  box-sizing: border-box;

  &.outlinedViolet {
    border: 1px solid $color-medium-slate-blue;
    color: $color-black;

    &:focus {
      border: 2px solid $color-medium-slate-blue;
      padding-left: 15px;
      padding-right: 39px;
    }

    &::placeholder {
      font-size: $font-size-m;
      font-weight: $font-weight-normal;
      color: $color-storm-grey;
      font-family: $font-family--popins;
    }

    &.readOnly {
      border-color: $color-quartz;
    }
  }

  &.outlinedGray {
    border: 1px solid $color-quartz;
    color: $color-black;

    &:focus {
      border: 2px solid $color-quartz;
      padding-left: 15px;
      padding-right: 39px;
    }

    &::placeholder {
      font-size: $font-size-m;
      font-weight: $font-weight-normal;
      color: $color-storm-grey;
      font-family: $font-family--popins;
    }

    &.readOnly {
      border-color: $color-quartz;
    }
  }
}

.textArea {
  min-height: 7rem;
  padding-bottom: $spacing-xm;
  padding-top: $spacing-xm;
}
