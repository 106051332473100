@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: $spacing-xxlg $spacing-layout-sm $spacing-layout-m $spacing-xxlg;
  overflow-y: auto;
}
