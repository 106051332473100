@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    color: $color-medium-slate-blue;
    height: 1.5rem;
    width: 1.5rem;
  }

  svg:hover {
    color: $color-slate-blue;
  }

  a:first-child {
    text-decoration: none !important;
    gap: $spacing-lg;
  }

  a {
    display: flex;
    color: $color-medium-slate-blue;
    gap: $spacing-m;
  }
}
