@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: $spacing-xxlg $spacing-layout-sm $spacing-layout-m $spacing-xxlg;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-lg;
}

.goBackWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-lg;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    color: $color-medium-slate-blue;
    height: 1.5rem;
    width: 1.5rem;
  }

  svg:hover {
    color: $color-slate-blue;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
