@import 'src/styles/variables';
@import 'src/styles/mixins';

.select {
  width: fit-content;
  height: 3.5rem;
  border: 0.063rem solid $color-medium-slate-blue;
  border-radius: $border-radius-input;
  box-shadow: 0 0 0 0.063rem transparent;  color: $color-black;
  font-weight: $font-weight-bold;
  font-family: $font-family--popins;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global {
    // overriding default Antd styles required !important
    .ant-select-selector {
      width: 100% !important;
      height: 100% !important;
      padding-left: 1rem !important;
      padding-right: 2.5rem !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;

      div {
        align-items: center;
      }
    }

    .ant-select-arrow {
      width: 0.5rem;
      height: 0.25rem;
      margin-right: 0.5rem;
      margin-top: 0;
    }

    .ant-select-selection-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
      color: $color-storm-grey;
      font-size: 1rem;
      font-weight: $font-weight-normal;
    }
  }
}

.focused {
  border: 0.125rem solid $color-medium-slate-blue;
}

.fullWidth {
  width: 100%;
}

.arrow {
  transform: translateY(-50%);
}

.arrowRotated {
  transform: translateY(-50%) rotate(180deg);
}

.icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown {
  background: #FFFFFF;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.75rem;
  margin-top: 0.25rem;
  max-height: 15.625rem;
}

.optionIcon {
  margin-right: 0.875rem;
}

.option {
  font-family: $font-family--popins !important;
  font-weight: $font-weight-normal;
  font-size: 1rem;
  margin: 0 1.5rem;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin-bottom: 1.15rem;
  }

  &:not(:last-child) {
    border-bottom: 0.063rem solid $color-quartz;
  }
}

.option:hover {
  color: $color-blue-text-hover;
}
