@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 75px calc(100% - 75px);
  grid-template-columns: 18rem auto;
  grid-template-areas:
    "header header"
    "sidebar content";
}

.wrapper {
  display: flex;
  max-width: 100%;
  width: 100%;
}

.content {
  grid-area: content;
  height: 100%;
  max-height: calc(100vh - 75px);
  width: 100%;
  overflow: auto;
}
