@import 'src/styles/variables';
@import 'src/styles/mixins';

.label {
  color: $color-black;
  font-size: $font-size-xsm;
  margin-bottom: $spacing-xsm;
}

.bold {
  font-weight: $font-weight-bold;
}
