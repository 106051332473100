@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  thead {
    background-color: #EFF3FB;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-row;
    text-align: center;
    color: $color-new-black;

    tr:first-child {
      font-size: $font-size-xlg;
      font-weight: $font-weight-extra-bold;

      th {
        vertical-align: middle;
      }
    }

    th {
      padding: $spacing-lg;
    }
  }

  tbody {
    tr {
      height: 3.5rem;
      text-align: center;

      td {
        vertical-align: middle;
        padding: $spacing-xm $spacing-lg;
        color: $color-new-black;
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

    }

    svg {
      height: 1.125rem;
      width: 1.125rem;
    }
  }
}

.headerElement {
  header {
    font-size: $font-size-m;
    font-weight: $font-weight-extra-bold;
  }

  div {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

.headerElementColored {
  header {
    color: #F2994A;
  }
}

.rowStart {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}

.row {
  border-radius: 4px;
}

.rowColored {
  background: $color-ghost-white-lighter;
}

.price {
  height: 1.5rem;
}

.rowColored {
  background-color: $color-ghost-white-lighter;
}


.headerSkeletonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.25rem;
  width: 100%;
  padding: 0 1.875rem;
}

.skeletonHeaderElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-m;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30rem;

  button {
    margin-top: $spacing-xm;
  }
}
