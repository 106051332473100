@import 'src/styles/variables';
@import 'src/styles/mixins';

.header {
  grid-area: header;
  display: flex;
  align-items: center;
  width: 100vw;
  border-bottom: 1px solid $color-quartz;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 24px 64px;

  @include vp--1239 {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include vp--1023 {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include vp--767 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.logo {
  display: flex;
  margin-right: 79px;

  a {
    cursor: pointer;
  }

  @include vp--1023 {
    margin-right: 39px;
  }

  @include vp--767 {
    position: absolute;
    left: 50%;
    order: 2;
    margin-right: 0;
    transform: translate(-50%, 0);
  }

  img {
    width: 146px;
    height: 24px;
  }
}

.link {
  display: flex;
  align-items: center;
  font-family: $font-family--popins;
  font-size: $font-size-m;
  line-height: 24px;
  font-weight: $font-weight-normal;
  letter-spacing: -0.01em;
  color: $color-black-russian;
  transition: opacity $trans--300;

  &:hover {
    opacity: 0.7;
  }
}

.linkDoc {
  margin-right: 10px;
  font-weight: $font-weight-bold;

  @include vp--767 {
    display: none;
  }

  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;

    @include vp--1023 {
      margin-right: 6px;
    }
  }
}

.linkBack {
  svg {
    margin-right: 17px;
    transform: rotate(180deg);
  }

  span {
    @include vp--767 {
      display: none;
    }
  }
}

.col {
  display: flex;
  align-items: center;
  max-width: 50%;
  width: 50%;
}

.colRight {
  position: relative;
  justify-content: space-between;
  padding-left: 24px;

  @include vp--1023 {
    padding-left: 10px;
  }

  @include vp--767 {
    justify-content: flex-end;
  }

  &::after {
    content: '';
    position: absolute;
    left: -1px;
    width: 1px;
    height: 24px;
    background-color: $color-quartz;

    @include vp--767 {
      display: none;
    }
  }
}
