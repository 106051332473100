@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  height: 7.625rem;
  border-radius: $border-radius-modal;
  background: linear-gradient(78.9deg, #000000 -0.27%, #102C8F 99.46%);
  position: inherit;
}

.background {
  position: absolute;
  width: 100%;
  object-fit: cover;
  opacity: 10%;
}

.contentWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xlg $spacing-xxlg $spacing-xlg $spacing-xlg;
  z-index: 1;
}

.planInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-m;
}


.actionGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-m;

  span {
    font-size: $font-size-xsm;
  }
}

.buttonsSkeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-m;
}

.wrapperPlanElementSkeleton {
  padding-right: $spacing-lg;
  margin-top: 0.188rem;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-xm;
  }
}

.info {
  padding-top: 0.063rem;
  padding-bottom: 0.063rem;
  padding-right: $spacing-lg;
  border-right: 0.063rem solid $color-storm-grey;
  font-size: $font-size-lg;
}
