@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
}

.wrapper:first-child {
  max-width: 10rem;
}

.viewKey {
  width: 130px;
}

.overflowed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
