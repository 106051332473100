@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: $border-radius-modal;
  background: linear-gradient(78.9deg, #000000 -0.27%, #102C8F 99.46%);
  padding: $spacing-xlg $spacing-xxlg $spacing-xlg $spacing-xlg;
}

.planElementWrapper {
  display: flex;
  gap: $spacing-m;
  width: fit-content;
}
.buttonsWrapper {
  display: flex;
  gap: $spacing-lg;
}

.info {
  padding-top: 0.063rem;
  padding-bottom: 0.063rem;
  padding-right: $spacing-lg;
  border-right: 0.063rem solid $color-storm-grey;
  font-size: $font-size-lg;
}

.wrapperPlanElementSkeleton {
  padding-right: $spacing-lg;
  margin-top: 0.188rem;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-xm;
  }
}

.buttonsSkeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-lg;
}
