@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.875rem 3rem 1.875rem 3.125rem;
  background-color: $color-ghost-white-lighter;
  height: 9.5rem;
  border-radius: $border-radius-modal;
}

.round {
  position: relative;
  min-height: 5.75rem;
  min-width: 5.75rem;
  background: $color-white;
  border-radius: 50%;
  border-left: 0.25rem solid #F3F5F8;
  border-bottom: 0.25rem solid #F3F5F8;
  border-right: 0.25rem solid #F3F5F8;
  transform: rotate(45deg);


  img {
    height: 3.75rem;
    width: 3.75rem;
    position: absolute;
    right: 0.875rem;
    top: 0;
    transform: rotate(-45deg);
  }
}

.enterprise {
  display: flex;

  h1 {
    font-weight: $font-weight-bold;
    font-size: $font-size-xlg;
  }
}

.iconSizeHandler {
  transform: scale(0.76);
}
