@import 'src/styles/variables';
@import 'src/styles/mixins';

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: $font-weight-bold;
}

.close {
  color: $color-medium-slate-blue;
  cursor: pointer;
  background: transparent;
  border: none;
}
.close:hover {
  color: $color-slate-blue;
}

.row {
  margin-bottom: 18px;
}


.button {
  margin-top: 24px;
  width: 170px;
}
