@import "src/styles/variables";

.wrapper {
  border-radius: $border-radius-input;
  overflow: hidden;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;

  :nth-last-child(2) {
    border-right: 0;
  }

  li {

    text-align: center;
    background: $color-default-white;
    margin-right: 0;
    border-radius: 0;
    border: none;

    border-right: 1px solid $color-black-russian;

    a {
      font-size: $font-size-sm;
      color: $color-default-black;
    }

  }

  li:hover {
    background: $color-ghost-white-lighter;
    border-color: $color-black-russian;

    a {
      color: $color-slate-blue
    }
  }

  :global {
    .ant-pagination {
      border: 1px solid $color-black-russian !important;
    }

    .ant-pagination-item-active a {
      color: $color-slate-blue
    }

    .ant-pagination-prev {
      display: none;
    }

    .ant-pagination-next {
      display: none;
    }
  }
}
