@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: absolute;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  min-height: 2.5rem;
  height: fit-content;
  top: $spacing-layout-sm;
  left: 10%;
  border-radius: $border-radius-button;
  padding: $spacing-sm $spacing-xm;

  button {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    cursor: pointer;
    border: none;
    background: transparent;
    text-decoration: underline;
  }
}
