@import "src/styles/variables";

.wrapper {
  width: 100%;
}

.paginationWrapper {
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 40px;
  margin-top: $spacing-xm;

  :last-child {
    margin-bottom: $spacing-xxsm;
  }
}
