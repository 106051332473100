@import 'styles/variables';
@import 'styles/mixins';


.noDataWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $font-size-lg;
  height: 12rem;
  width: 100%;

  button {
    margin-top: $font-size-m;
  }

  div {
    color: $color-black;
    text-align: center;
  }
}
