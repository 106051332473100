@import 'src/styles/variables';
@import 'src/styles/mixins';

.overlay {
  width: 100%;
  height: 7.063rem;
  background-color: $color-medium-slate-blue;
  border-radius: $border-radius-button;
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-xm;

  button {
    cursor: pointer;
    padding: $spacing-xm 0;
    height: 3.5rem;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 1rem;

    svg {
      margin-right: $spacing-sm;
    }
  }
}

.overlay > button:first-child {
  border-bottom: 0.063rem solid $color-quartz;
}
