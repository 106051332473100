@import 'styles/variables';
@import 'styles/mixins';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-xm;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 15rem;
  padding: $spacing-xlg;
  border-radius: $border-radius-modal;
  background: $color-ghost-white-lighter;

  a {
    color: $color-medium-slate-blue;
    text-decoration: underline;
  }

  a:hover {
    color: $color-slate-blue;
  }

  ul {
    margin-left: $spacing-m;

    li {
      margin-left: 0.313rem;
      padding-left: $spacing-xm;
      font-weight: $font-weight-normal;
      list-style-type: initial;
    }

    ::marker {
      font-size: 75%;
      color: $color-medium-slate-blue;
    }
  }

}

.title {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
}
