@import 'src/styles/variables';
@import 'src/styles/mixins';


.modal {
  background: $color-white;
  padding: 0;
  border-radius: $border-radius-modal;

  :global {
    .ant-modal-footer {
     display: none
    }
    .ant-modal-content {
      border-radius: $border-radius-modal;
    }
    .ant-modal-body {
      padding: 40px;
    }
  }
}

.modal > div {
  box-shadow: none;
  width: 100%;
}
