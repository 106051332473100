@font-face {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-family: 'HelveticaNeue';

  font-display: swap;
  src: url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
  url('../fonts/HelveticaNeue-Medium.woff') format('woff');
}

@font-face {
  font-weight: $font-weight-normal;
  font-style: normal;
  font-family: 'NeueMachina';

  font-display: swap;
  src: url('../fonts/NeueMachina-Regular.woff2') format('woff2'),
  url('../fonts/NeueMachina-Regular.woff') format('woff');
}

@font-face {
  font-weight: $font-weight-normal;
  font-style: normal;
  font-family: 'Poppins';

  font-display: swap;
  src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
  url('../fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-weight: $font-weight-bold;
  font-style: normal;
  font-family: 'Poppins';

  font-display: swap;
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
  url('../fonts/Poppins-Medium.woff') format('woff');
}
