@import 'src/styles/variables';
@import 'src/styles/mixins';

.input {
  margin-bottom: 32px;
}

.form {
  margin-top: 32px;
}

.errorWrapper {
  position: absolute;
  color: $color-cinnabar;
}

.error {
  input {
    border-color: $color-cinnabar;
  }
}

.submit {
  margin-bottom: 32px;
}

.submitContent {
  margin-top: 32px;
  height: 78px;

  div {
    margin: 0 auto;
  }
}


