@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  cursor: pointer;
  width: fit-content;
  border-radius: $border-radius-button;
  display: flex;
  justify-content: center;
  align-items: center;

  &.large {
    height: 3.5rem;
    padding: $spacing-xm $spacing-lg;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
  }

  &.medium {
    height: 2.5rem;
    padding: 0.563rem 1rem;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
  }

  &.small {
    height: 2rem;
    padding: 0.25rem 1.125rem;
    font-size: $font-size-xsm;
    font-weight: $font-weight-bold;
  }

  &.filledSuccess {
    background: $color-success !important;
    color: $color-white;
    border: 0.063px solid $color-success;

    &:hover {
      background-color: $color-success-hover !important;
      color: $color-white;
      border: 0.063px solid $color-success-hover;
    }

    &:disabled {
      border-color: $color-quartz !important;
      background-color: $color-quartz;
      cursor: default;
      color: $color-white;

      &.loading {
        background-color: $color-success !important;
        color: $color-white;
        border: 0.063px solid $color-success;
      }
    }

    &:enabled:active {
      background-color: $color-success !important;
      color: $color-white;
      border: 0.063px solid $color-success;
    }
  }

  &.filledError {
    background-color: $color-sunset-orange !important;
    color: $color-white;
    border: 0.063px solid $color-sunset-orange;

    &:hover {
      background-color: $color-sunset-orange-hover !important;
      color: $color-white;
      border: 0.063px solid $color-sunset-orange-hover;
    }

    &:disabled {
      border-color: $color-quartz;
      background-color: $color-quartz !important;
      cursor: default;
      color: $color-white;

      &.loading {
        background-color: $color-sunset-orange !important;
        color: $color-white;
        border: 0.063px solid $color-sunset-orange;
      }
    }

    &:enabled:active {
      background-color: $color-sunset-orange-hover !important;
      color: $color-white;
      border: 0.063px solid $color-sunset-orange-hover;
    }
  }

  &.white {
    background-color: transparent;

    &.filled {
      background-color: $color-medium-slate-blue;
      color: $color-white;
      border: 0.063rem solid $color-medium-slate-blue;


      &:hover {
        background-color: $color-blue-background-hover;
        border-color: $color-blue-background-hover;
      }

      &:disabled {
        border-color: $color-quartz;
        background-color: $color-quartz;
        cursor: default;

        &.loading {
          background-color: $color-medium-slate-blue;
          color: $color-white;
          border: 0.063rem solid $color-medium-slate-blue;
        }
      }

      &:enabled:active {
        background-color: $color-medium-slate-blue;
      }
    }

    &.outlinedViolet {
      color: $color-medium-slate-blue;
      border: 0.063rem solid $color-medium-slate-blue;
      background-color: transparent;

      &:hover {
        background-color: $color-blue-background-hover;
        color: $color-white;
      }

      &:disabled {
        border-color: $color-grey;
        background-color: transparent;
        color: $color-grey;
        cursor: default;

        &.loading {
          color: $color-medium-slate-blue;
          border: 0.063rem solid $color-medium-slate-blue;
        }
      }

      &:enabled:active {
        background-color: transparent;
        color: $color-medium-slate-blue;
        border: 0.063rem solid $color-medium-slate-blue;
      }
    }

    &.outlinedGray {
      background-color: transparent;
      border: 0.063rem solid $color-quartz;
      color: $color-black-russian;

      svg path {
        color: $color-selective-yellow;
      }

      &:hover {
        background-color: $color-blue-background-hover;
        border: 0.063rem solid $color-blue-background-hover;
        color: $color-white;

        svg path {
          color: $color-white;
        }
      }

      &:disabled {
        background-color: transparent;
        border: 0.063rem solid $color-grey;
        color: $color-grey;
        cursor: default;

        svg path {
          color: $color-grey-disabled;
        }

        &.loading {
          border: 0.063rem solid $color-grey-disabled;
          color: $color-white;
          background-color: transparent;
        }
      }

      &:enabled:active {
        border: 0.063rem solid $color-quartz;
        color: $color-black-russian;
        background-color: transparent;

        svg path {
          color: $color-selective-yellow;
        }
      }

    }
  }

  &.black {
    background-color: transparent;

    &.filled {
      border: 0.063rem solid $color-white;
      color: $color-white;
      background-color: transparent;

      &:hover {
        background-color: $color-blue-background-hover;
        border: 0.063rem solid $color-blue-background-hover;
      }

      &:disabled {
        background-color: transparent;
        border: 0.063rem solid $color-grey-disabled;
        color: $color-grey-disabled;
        cursor: default;

        &.loading {
          border: 0.063rem solid $color-white;
          color: $color-white;
          background-color: $color-default-black;
        }
      }

      &:enabled:active {
        border: 0.063rem solid $color-white;
        color: $color-white;
        background-color: transparent;
      }
    }

    &.outlinedGray {
      background-color: transparent;
      border: 0.063rem solid $color-grey-disabled;
      color: $color-white;

      svg path {
        color: $color-selective-yellow;
      }

      &:hover {
        background-color: $color-blue-background-hover;
        border: 0.063rem solid $color-blue-background-hover;

        svg path {
          color: $color-white;
        }
      }

      &:disabled {
        background-color: transparent;
        border: 0.063rem solid $color-grey-disabled;
        color: $color-grey-disabled;
        cursor: default;

        svg path {
          color: $color-grey-disabled;
        }

        &.loading {
          border: 0.063rem solid $color-grey-disabled;
          color: $color-white;
          background-color: transparent;
        }
      }

      &:enabled:active {
        border: 0.063rem solid $color-grey-disabled;
        color: $color-white;
        background-color: transparent;

        svg path {
          color: $color-selective-yellow;
        }
      }

    }

    &.outlinedIcon {
      border: 0.063rem solid $color-storm-grey;

      svg path {
        color: $color-white;
      }

      &:hover {
        background-color: $color-blue-background-hover;
        border: 0.063rem solid $color-blue-background-hover;

        svg path {
          color: $color-white;
        }
      }

      &:disabled {
        background-color: transparent;
        border: 0.063rem solid $color-grey-disabled;
        color: $color-grey-disabled;
        cursor: default;

        svg path {
          color: $color-grey-disabled;
        }

        &.loading {
          border: 0.063rem solid $color-storm-grey;
          background-color: transparent;
        }
      }

      &:enabled:active {
        border: 0.063rem solid $color-storm-grey;
        background-color: transparent;

        svg path {
          color: $color-white;
        }
      }
    }

    &.outlinedTransparent {
      border: 0.063rem solid transparent;
      color: $color-white;

      &:hover {
        background-color: $color-blue-background-hover;
        border: 0.063rem solid $color-blue-background-hover;

        svg path {
          color: $color-white;
        }
      }

      &:disabled {
        background-color: transparent;
        border: 0.063rem solid $color-grey-disabled;
        color: $color-grey-disabled;
        cursor: default;

        svg path {
          color: $color-grey-disabled;
        }

        &.loading {
          border: 0.063rem solid $color-storm-grey;
          background-color: transparent;
        }
      }

      &:enabled:active {
        border: 0.063rem solid $color-storm-grey;
        background-color: transparent;

        svg path {
          color: $color-white;
        }
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.loaderContainer {
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 0.875rem;
  height: 0.875rem;
  border-bottom: 0.125rem solid #C4C4C4;
  border-right: 0.125rem solid #C4C4C4;
  border-top: 0.125rem solid $color-white;
  border-left: 0.125rem solid $color-white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: spinner 1.5s linear infinite;
}

.loaderGray {
  border-top: 0.125rem solid $color-quartz;
  border-left: 0.125rem solid $color-quartz;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
  }
}

