@import 'src/styles/variables';
@import 'src/styles/mixins';

.sidebar {
  grid-area: sidebar;
  padding-left: $spacing-layout-sm;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: calc(100vh - 4.563rem);
  border-right: 0.063rem solid $color-quartz;
  padding-bottom: $spacing-xm;

  .wrapper a {
    position: relative;
    padding-left: $spacing-xm;
    font-family: $font-family--popins;
    font-weight: $font-weight-bold;
    font-size: $font-size-m;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
    color: $color-black-russian;
    display: flex;
    align-items: center;

    &:hover, &.activeLink {
      text-decoration: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 0.125rem;
        height: 1.5rem;
        background-color: $color-medium-slate-blue;
      }
    }

    & > * {
      margin-left: $spacing-lg;
    }

    &:not(:last-child) {
      margin-bottom: $spacing-lg;
    }

    &.activeLink {
      pointer-events: none;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-xxlg;
  flex: 1;
}

.dropdown {
  position: absolute;
  left: 5.5rem;
  top: -1.75rem;
  background: $color-default-white;
  box-shadow: 0 0.063rem 0.25rem rgba(35, 55, 86, 0.14);
  border-radius: $border-radius-button;
  width: 16.25rem;
  max-height: 44.375rem;
  padding: $spacing-m $spacing-lg;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
}

.link {
  height: 3rem;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden;
  text-align: left;
  max-width: 13.125rem;
  padding: $spacing-m 0;
  cursor: pointer;
  border: none;
  background: transparent;
  border-bottom: 0.063rem solid $color-quartz;
  color: $color-black-russian;
  text-decoration: none !important;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

.dropdownContent {
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-success;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: $spacing-m 0;
  }

  button:disabled {
    color: $color-storm-grey;
    cursor: default;
  }

  button:hover:not(:disabled) {
    color: darken($color-success, 5%)
  }

  a:hover {
    color: $color-blue-text-hover !important;
  }

  a:not(:first-child):hover {
    background: $color-ghost-white-lighter;
  }

  a:last-child {
    border-bottom: none;
  }
}

.buttonWithBorder {
  border-bottom: 0.063rem solid $color-quartz;
}

.appsButton {
  position: inherit;
  text-align: left;
  border: none;
  background: transparent;
  font-size: $font-size-m;
  font-weight: $font-weight-bold;
  cursor: pointer;
  color: $color-default-black;
  padding-left: $spacing-m;
  border-left: 0.125rem solid transparent;
  margin-bottom: $spacing-lg;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.appsButtonColored {
  color: $color-blue-background-hover
}

.rotate {
  transform: rotate(180deg);
}

.skeletonAddApp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-m;
  padding: $spacing-m 0;
  border-bottom: 0.063rem solid $color-quartz;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.skeletonApp {
  div {
    padding: $spacing-xm 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &.bordered {
    border-bottom: 0.063rem solid $color-quartz;
  }
}

.active {
  border-left: 0.125rem solid $color-medium-slate-blue;
}

.activeKey {
  font-weight: $font-weight-extra-bold !important;
  background-color: #E6F7FF !important;
}

