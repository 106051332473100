@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: $spacing-xm;
}

.subscriptionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-xm;
  flex-wrap: wrap;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
