@import "variables";
@import "mixins";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;

  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: $font-size-m;
  line-height: 24px;
  font-family: $font-family--popins;
  color: $color-black-russian;
  letter-spacing: 0.02em;
  background-color: $color-default-white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family--neue-machina;
}

a {
  color: $color-default-white;
  text-decoration: none;
  font-weight: $font-weight-bold;

  &:hover {
    text-decoration: underline;
  }
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}
