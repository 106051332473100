@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: fit-content;
  width: fit-content;
  display: inherit;
}

.fullWidth {
  width: 100%;
}

.around {
  &__small {
    margin: $spacing-sm;
  }

  &__medium {
    margin: $spacing-m;
  }

  &__extra-medium {
    margin: $spacing-xm;
  }

  &__large {
    margin: $spacing-lg;
  }

  &__extra-large {
    margin: $spacing-xlg;
  }

  &__xextra-large {
    margin: $spacing-xxlg;
  }
}

.bottom {
  &__small {
    margin-bottom: $spacing-sm;
  }

  &__medium {
    margin-bottom: $spacing-m;
  }

  &__extra-medium {
    margin-bottom: $spacing-xm;
  }

  &__large {
    margin-bottom: $spacing-lg
  }

  &__extra-large {
    margin-bottom: $spacing-xlg
  }

  &__xextra-large {
    margin-bottom: $spacing-xxlg;
  }
}

.top {
  &__small {
    margin-top: $spacing-sm;
  }

  &__medium {
    margin-top: $spacing-m;
  }

  &__extra-medium {
    margin-top: $spacing-xm;
  }

  &__large {
    margin-top: $spacing-lg
  }

  &__extra-large {
    margin-top: $spacing-xlg;
  }

  &__xextra-large {
    margin-top: $spacing-xxlg;
  }
}

.left {
  &__small {
    margin-left: $spacing-sm;
  }

  &__medium {
    margin-left: $spacing-m;
  }

  &__extra-medium {
    margin-left: $spacing-xm;
  }

  &__large {
    margin-left: $spacing-lg
  }

  &__extra-large {
    margin-left: $spacing-xlg;
  }

  &__xextra-large {
    margin-left: $spacing-xxlg;
  }
}

.right {
  &__small {
    margin-right: $spacing-sm;
  }

  &__medium {
    margin-right: $spacing-m;
  }

  &__extra-medium {
    margin-right: $spacing-xm;
  }

  &__large {
    margin-right: $spacing-lg
  }

  &__extra-large {
    margin-right: $spacing-xlg;
  }

  &__xextra-large {
    margin-right: $spacing-xxlg;
  }
}

.left-right {
  &__small {
    margin: 0 $spacing-sm;
  }

  &__medium {
    margin: 0 $spacing-m;
  }

  &__extra-medium {
    margin: 0 $spacing-xm;
  }

  &__large {
    margin: 0 $spacing-lg
  }

  &__extra-large {
    margin: 0 $spacing-xlg;
  }

  &__xextra-large {
    margin: 0 $spacing-xxlg;
  }
}

.top-bottom {
  &__small {
    margin: $spacing-sm 0
  }

  &__medium {
    margin: $spacing-m 0
  }

  &__extra-medium {
    margin: $spacing-xm 0
  }

  &__large {
    margin: $spacing-lg 0
  }

  &__extra-large {
    margin: $spacing-xlg 0
  }

  &__xextra-large {
    margin: $spacing-xxlg 0
  }
}
