@import 'src/styles/variables';
@import 'src/styles/mixins';


.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.productsWrapperPlacement {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productsWrapper {
  display: flex;
  border-radius: $border-radius-modal;
  background-color: $color-ghost-white-lighter;
  width: fit-content;
}

.productsWrapper > div {
  width: 100%;
  max-width: 20rem;
  min-width: 15.5rem;
}

.productsWrapper > div:not(:last-child) {
  border-right: 0.063rem solid $color-quartz;
}

.productsWrapper > div:last-child {
  border-top-right-radius: $border-radius-modal;
  border-bottom-right-radius: $border-radius-modal;
}

.productsWrapper > div:first-child {
  border-top-left-radius: $border-radius-modal;
  border-bottom-left-radius: $border-radius-modal;
}

.comparePlansButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  text-decoration: none !important;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30rem;
  width: 100%;

  button {
    margin-top: $spacing-xm;
  }
}
