@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-xsm;
}

.wrapper > div {
  padding: $spacing-lg;
  width: 100%;
  background-color: $color-ghost-white-lighter;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    font-size: $font-size-m;
    font-weight: $font-weight-extra-bold;
  }

  span {
    color: $color-storm-grey;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
  }
}

.wrapper > div:first-child {
  border-top-left-radius: $border-radius-modal;
  border-bottom-left-radius: $border-radius-modal;
}

.wrapper > div:last-child {
  border-top-right-radius: $border-radius-modal;
  border-bottom-right-radius: $border-radius-modal;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  padding: $spacing-xm;
  background-color: $color-white;
  border-radius: $border-radius-button;
  gap: $spacing-m;

  button {
    font-size: $font-size-sm;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  button:hover {
    color: $color-blue-background-hover;
  }

}

.periodButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-xsm;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: $color-medium-slate-blue;
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
  z-index: 1;
}

.periodButton:enabled:active {
  color: $color-blue-background-hover;
}

.periodButton:disabled {
  color: $color-storm-grey;
}

.arrowRotated {
  margin-top: $spacing-xsm;
  transform: translateY(-50%) rotate(180deg);
}

.selectedOption {
  color: $color-medium-slate-blue;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $font-size-lg;
  height: 16rem;
  width: 100%;

  button {
    margin-top: $font-size-m;
  }

  div {
    color: $color-black;
    text-align: center;
  }
}
