@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding-right: $spacing-lg;

  img {
    height: 1.5rem;
  }

  span {
    color: #ffffffb3;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-xsm;
  }

  div {
    color: $color-white;
    display: flex;
    gap: $spacing-xm;
  }
}

.info {
  padding-right: $spacing-lg;
  border-right: 0.063rem solid $color-storm-grey;
  font-size: $font-size-lg;
}

.error {
  color: $color-sunset-orange !important;
}

.wrapperPlanElementSkeleton {
  padding-right: $spacing-lg;
  margin-top: 0.188rem;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-xm;
  }
}

