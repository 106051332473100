@import 'src/styles/variables';
@import 'src/styles/mixins';

.small {
  height: $icon-size-sm;
}

.medium {
  height: $icon-size-m;
}

.large {
  height: $icon-size-lg;
}

.extraLarge {
  height: $icon-size-xlg;
}

