@import 'src/styles/variables';
@import 'src/styles/mixins';

.popup {
  display: flex;
  position: absolute;
  right: 0;
  top: 0.25rem;
  width: 12.125rem;
  height: 3rem;
  background-color: $color-black-russian;
  border-radius: 0.75rem 0 0.75rem 0.75rem;
  transition: opacity $trans--300;
  z-index: 1;

  &:hover,
  &:active {
    opacity: 0.9;
  }

  @include vp--767 {
    width: 110px;
    left: -60px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    width: 100%;
    height: 100%;
    font-family: $font-family--popins;
    font-size: $font-size-sm;
    line-height: 20px;
    font-weight: $font-weight-bold;
    letter-spacing: -0.01em;
    color: $color-default-white;
  }
}

.popupWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}
